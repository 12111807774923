import ReactDOMServer from 'react-dom/server';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Map as MapBox, Marker, Popup, NavigationControl } from 'mapbox-gl';

import useWindowsDimension from '../../hooks/useWindowDimensions';

import MapPopUp from './MapPopUp';

const Map = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { height } = useWindowsDimension();

    const mapContainer = useRef(null);

    const generateMap = useCallback(() => {
        let info = props.data;
        let coordinates = [info.coordinates.longitude, info.coordinates.latitude];
        
        let locations = { 
            type: 'FeatureCollection',
            features: []
        };

        for (let i = 0; i < info.data.length; i++) {
            let item = {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [info.data[i].longitude, info.data[i].latitude]
                },
                properties: {
                    uuid: info.data[i].id,
                    name: info.data[i].name,
                    slug: info.data[i].slug,
                    photo: info.data[i].photo,
                }
            };

            locations.features.push(item);
        }
        
        const map = new MapBox({
            container: mapContainer.current,
            attributionControl: false,
            style: 'mapbox://styles/mapbox/streets-v11',
            zoom: 10,
            maxZoom: 24,
            minZoom: 2,
            center: coordinates,
            // center: [-88.9426 , 13.8767],
        });

        map.addControl(new NavigationControl());

        locations.features.map((marker) => {
            let element = document.createElement('div');

            element.className = 'marker-public';

            new Marker(element)
            .setLngLat(marker.geometry.coordinates)
            .setPopup(
                new Popup({ offset: 25 })    
                .setHTML(
                    ReactDOMServer.renderToString(
                        <MapPopUp 
                            name={ marker.properties.name }
                            slug={ marker.properties.slug }
                            photo={ marker.properties.photo }
                        />
                    )
                )    
            )
            .addTo(map);
        });

        setIsLoading(true);
    }, []);

    const renderHeightMap = () => {
        let result;
        
        result = (height - 288);

        if (height > 800) {
            result = (height - 315);
        }

        if (height > 900) {
            result = (height - 288);
        }

        return result;
    };

    useEffect(() => {
        if (isLoading === false) {
            generateMap();
        }
    }, []);

    return (
        <div className="w-full bg-white">
            <div id="map-results" className="w-full" style={{ 'height': `${renderHeightMap()}px` }} ref={ mapContainer } />
        </div>
    );
};

export default Map;
