import { useCallback, useEffect, useRef, useState } from 'react';
import { Map as MapBox, Marker, Popup, NavigationControl } from 'mapbox-gl';

import useWindowDimensions from '../../hooks/useWindowDimensions';

const Ubication = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { height } = useWindowDimensions();

    const mapContainer = useRef(null);

    const generateMap = useCallback(() => {
        console.log(props.latitude);
        console.log(props.longitude);
        const map = new MapBox({
            container: mapContainer.current,
            attributionControl: false,
            style: 'mapbox://styles/mapbox/streets-v11',
            zoom: 18,
            center: [props.longitude , props.latitude],
        });

        let location = { 
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [props.longitude, props.latitude]
                    }, 
                    properties: null
                }
            ]
        };

        // for (let i = 0; i < props.data.length; i++) {
        //     let item = {
        //         type: 'Feature',
        //         geometry: {
        //             type: 'Point',
        //             coordinates: [props.data[i].longitude, props.data[i].latitude]
        //         },
        //         properties: {
        //             uuid: props.data[i].id,
        //             name: props.data[i].name,
        //             slug: props.data[i].slug,
        //             photo: props.data[i].photo,
        //         }
        //     };

        //     locations.features.push(item);
        // }
        map.addControl(new NavigationControl());
        location.features.map((marker) => {
            let element = document.createElement('div');

            element.className = 'marker-public';

            new Marker(element)
            .setLngLat(marker.geometry.coordinates)
            .addTo(map);
        });

        map.scrollZoom.disable();

        setIsLoading(true);
    }, []);

    useEffect(() => {
        if (isLoading === false) {
            generateMap();
        }
    }, []);

    return (
        <div className="w-full bg-white">
            {/* <div className='text-dark'>{ height }</div> */}
            <div id="map-details" className="w-full h-map-details" ref={ mapContainer } />
        </div>
    );
};

export default Ubication;
