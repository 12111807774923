import axios from 'axios';
import { useCallback, useEffect, useState } from "react";

import Navbar from "../layout/Navbar";
import Navigate from "../layout/Navigate";
import Footer from "../layout/Footer";

import Banner from '../assets/img/banner.png';
import Brand from '../assets/img/brand.jpg';

const preload = [1, 2, 3, 4];

const About = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState(undefined);

    const loadData = useCallback(() => {
        let uri = `${process.env.REACT_APP_API_URI}category/all/active`;
        
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }
        
        axios.get(uri, config)
        .then(response => {
            setItems(response.data);
        })
        .catch(error => {
            console.log(error);
        });

        setIsLoading(true);
    } ,[]);

    useEffect(() => {
        if (isLoading === false) {
            loadData();
        }
    }, []);

    return (
        <div id="About" className="h-full">
            <div className="h-full">
                <div className="w-full h-fit">
                    <Navbar before={ "/search" } />
                </div>
                <div className="w-full">
                    <section className="text-white h-full w-full mt-5">
                        <div className="md:mx-14 mx-7 text-center mb-7">
                            <h1 className="text-3xl font-bold">Acerca de</h1>
                        </div>
                        <div className="mb-7 flex justify-center">
                            <div className="bg-white p-12 rounded-full w-80 h-80 flex items-center justify-center">
                                <img 
                                    src={ Brand }
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="mb-7 mx-8 md:mx-14 lg:mx-24">
                            <div className="w-full">
                                <p className="text-justify text-lg">
                                    <b>BienEStar Escolar</b> es una aplicación diseñada para las Consejerías Escolares y Comunidades Educativas de los centros escolares salvadoreños en la cual podrán identificar diversos servicios a través de georreferenciación a nivel nacional.
                                </p>
                            </div>
                            <br />
                            <div className="w-full">
                                <h1 className="text-2xl font-bold text-center">Servicios disponibles</h1>
                            </div>
                            <br />
                            <div className="w-full">
                                <div className="flex flex-wrap" >
                                { items !== undefined && (
                                    items.map((item, key) => {
                                        return (
                                            <div key={ key } className="lg:w-1/3 w-full p-4">
                                                <div className="flex bg-theme rounded-lg p-8 items-center justify-center md:flex-row flex-col">
                                                    <div className="w-16 h-16 inline-flex items-center justify-center rounded-full bg-body flex-shrink-0 md:mr-4 md:mb-0 mb-3">
                                                        <img
                                                            src={ process.env.REACT_APP_URI_PHOTO + item.icon }
                                                            className="w-8 h-8"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="md:flex-grow w-full md:text-left text-center">
                                                        <h2 className="text-xl font-medium rounded-lg">
                                                            { item.name }
                                                        </h2>
                                                        <span className="text-base">{ item.quantity } { item.quantity > 1 ? "servicios disponibles" : "servicio disponible" }</span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) }
                                    { items === undefined && (
                                        preload.map((item) => {
                                            return (
                                                <div key={ item } className="lg:w-1/2 w-full p-4">
                                                    <div className="flex bg-theme rounded-lg p-8 items-center justify-center md:flex-row flex-col">
                                                        <div className="w-16 h-16 rounded-full bg-body flex-shrink-0 animate-pulse md:mr-4 md:mb-0 mb-3">
                                                            <br />
                                                        </div>
                                                        <div className="md:flex-grow w-full">
                                                            <h2 className="bg-body text-xl font-medium rounded-lg animate-pulse ">
                                                                <br />
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="w-full">
                    <div className="bg-white flex justify-center w-full xl:py-6 md:py-3 md:px-0 py-5 px-2">
                        <img
                            src={ Banner }
                            className="2xl:w-auto md:w-1/3 w-auto h-auto"
                            alt=""
                        />
                    </div>
                    <Footer />
                </div>
                <div className="w-full h-fit">
                    <Navigate />
                </div>
            </div>
        </div>
    );
};

export default About;
