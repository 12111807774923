import { Fragment } from 'react';
import { Routes, Route} from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { 
	faHouse,
	faRankingStar,
	faExclamationCircle,
	faAngleLeft,
	faHouseChimneyMedical,
	faUser,
	faLocationDot,
	faPhone,
	faEnvelope,
	faLocationArrow,
	faBriefcaseMedical,
	faFileLines
} from '@fortawesome/free-solid-svg-icons';

import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons'

// Views
import Home from './views/Home';
import Searcher from './views/Searcher';
import Results from './views/Results';
import Details from './views/Details';
import Ranking from './views/Ranking';
import About from './views/About';

import ScrollToTop from './layout/ScrollToTop';

import './assets/css/App.css';

library.add(faHouse, faRankingStar, faExclamationCircle, faAngleLeft, faHouseChimneyMedical, faUser, faLocationDot, faPhone, faEnvelope, faLocationArrow, faClock, faBriefcaseMedical, faFileLines, faFacebook, faInstagram, faTwitter);

const App = () => {
	return (
		<div id="App">
			<Fragment>
				<ScrollToTop />
				<Routes>
					<Route path="/" element={ <Home /> } />
					<Route path="/search" element={ <Searcher /> } />
					<Route path="/results" element={ <Results /> } />
					<Route path="/details/:slug" element={ <Details /> } />
					<Route path="/ranking" element={ <Ranking /> } />
					<Route path="/About" element={ <About /> } />
				</Routes>
			</Fragment>
		</div>
	);
}

export default App;
