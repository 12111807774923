import { Link } from 'react-router-dom';

import Footer from '../layout/Footer';

import Banner from '../assets/img/banner.png';
import Brand from '../assets/img/brand.jpg';

import '../assets/css/Home.css';

const Home = () => {
    return (
        <div id="Home" className="h-screen">
            <div className="w-full h-screen">
                <div className="flex flex-wrap items-center w-full">
                    <div className="lg:w-7/12 md:w-7/12 w-full h-full">
                        <div className="flex items-center justify-center h-full flex-col bg-white 2xl:py-28 py-10 home-rounded">
                            <div className="text-center font-comfortaa font-bold 2xl:text-5xl lg:text-4xl text-4xl text-theme pb-10">
                                <h1>Bien<span className="text-body" >ES</span>tar</h1>
                                <h1>Escolar</h1>
                            </div>
                            <img 
                                src={ Brand }
                                className="md:px-0 md:pb-0 px-5 pb-5 lg:w-1/3 object-center w-10/12 h-auto"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="lg:w-5/12 md:w-5/12 w-full">
                        <div className="flex justify-center md:mt-12 md:mb-6 mt-10 mb-5">
                            <Link to="/search" className="bg-theme text-white border-2 border-theme px-10 py-4 2xl:text-2xl md:text-xl text-lg rounded-full font-normal transition-colors hover:bg-dark-theme hover:border-dark-theme">Búsqueda de servicios</Link>
                        </div>
                        <div className="flex justify-center md:mb-12 mb-10">
                            <Link to="/about" className="bg-white text-theme border-2 border-theme px-10 py-4 2xl:text-2xl md:text-xl text-base rounded-full font-normal transition-colors hover:bg-theme hover:text-white">Servicios disponibles</Link>
                        </div>
                    </div>
                    <div className="bg-white flex md:justify-end justify-center w-full xl:py-6 md:py-3 md:px-0 py-5 px-2">
                        <img
                            src={ Banner }
                            className="2xl:w-auto md:w-1/3 w-auto h-auto"
                            alt=""
                        />
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Home;
