import { useLocation, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Navigate = () => {
	const location = useLocation();

    return (
        <div id="Navigate">
            <div className="h-16"></div>
            <div className="fixed bottom-0 right-0 left-0 z-10 bg-white">
                <div className="flex flex-wrap">
                    <div className="w-full lg:mx-28">
                        <div className="flex w-full relative justify-evenly">
                            <div className="w-20">
                                <Link to="/" className="navigate-item">
                                    <div className={ `h-1 bg-transparent rounded-b-lg${ location.pathname === '/' || location.pathname === '/search' ? " bg-theme" : "" }` }></div>
                                    <div className="py-2 text-center">
                                        <div className="flex flex-wrap">
                                            <div className={ `item-option w-full${ location.pathname === '/' || location.pathname === '/search' ? " text-theme" : "" }` }>
                                                <FontAwesomeIcon icon="fa-solid fa-house" />
                                            </div>
                                            <div className="w-full text-base font-normal">
                                                <span>Inicio</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="w-20">
                                <Link to="/ranking" className="navigate-item">
                                    <div className={ `h-1 bg-transparent rounded-b-lg${ location.pathname === '/ranking' ? " bg-theme" : "" }` }></div>
                                    <div className="py-2 text-center">
                                        <div className="flex flex-wrap">
                                            <div className={ `item-option w-full${ location.pathname === '/ranking' ? " text-theme" : "" }` }>
                                                <FontAwesomeIcon icon="fa-solid fa-ranking-star" />
                                            </div>
                                            <div className="w-full text-base font-normal">
                                                <span>Ranking</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="w-20">
                                <Link to="/about" className="navigate-item">
                                    <div className={ `h-1 bg-transparent rounded-b-lg${ location.pathname === '/about' ? " bg-theme" : "" }` }></div>
                                    <div className="py-2 text-center">
                                        <div className="flex flex-wrap">
                                            <div className={ `item-option w-full${ location.pathname === '/about' ? " text-theme" : "" }` }>
                                                <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" />
                                            </div>
                                            <div className="w-full text-base font-normal">
                                                <span>Acerca de</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navigate;
