import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { generateAlert } from '../utils/utils';

import Navbar from "../layout/Navbar";
import Navigate from "../layout/Navigate";
import Preload from '../layout/Preload';

const Searcher = () => {
    const { register, handleSubmit, formState: { errors }, reset, clearErrors } = useForm();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const [types, setTypes] = useState([]);
    const [services, setServices] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const loadTypes = useCallback(() => {
        let uri = `${process.env.REACT_APP_API_URI}client/types`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }

        axios.get(uri, config)
        .then(response => {
            setTypes(response.data);
        })
        .catch(error => {
            console.log(error);
        });
    }, []);

    const loadServices = useCallback(() => {
        let uri = `${process.env.REACT_APP_API_URI}category/all/active`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }

        axios.get(uri, config)
        .then(response => {
            setServices(response.data);
        })
        .catch(error => {
            console.log(error);
        });

        setIsLoading(true);
    }, []);

    const loadStates = useCallback((e) => {
        let uuid = e.target.value;
        // console.log(uuid);
        let uri = `${process.env.REACT_APP_API_URI}state/centers/${uuid}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }

        axios.get(uri, config)
        .then(response => {
            setStates(response.data);
        })
        .catch(error => {
            console.log(error);
        });

        setIsLoading(true);
    }, []);

    const saveRecord = (data) => {
        let uri = `${process.env.REACT_APP_API_URI}record`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }

        axios.post(uri, JSON.stringify(data), config)
        .then(response => {
            console.log(response.data);
        })
        .catch(error => {
            console.log(error);
        });
    };

    const sendData = (data) => {
        let uri = `${process.env.REACT_APP_API_URI}center/search`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }

        axios.post(uri, JSON.stringify(data), config)
        .then(response => {
            let result = response.data;

            if (result.type !== undefined) {
                generateAlert(result);

                return;
            }

            saveRecord(data);
            sessionStorage.setItem("search", JSON.stringify(data));

            navigate('/results');
        })
        .catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        if (sessionStorage.getItem('search') !== null) {
            navigate('/results');
        }

        if (isLoading === false) {
            loadTypes();
            loadServices();
            // loadStates();
        }
    }, []);    

    return (
        <div id="Searcher" className="h-full">
            <div className="flex flex-wrap h-content-webkit">
                <div className="w-full h-fit">
                    <Navbar before={ "/" } />
                </div>
                <div className="w-full">
                    <section className="md:mx-14 mx-7 pb-4 text-white h-full flex items-center justify-center ">
                        { (services.length > 0) && (
                            <form id="search" className="w-full" onSubmit={ handleSubmit(sendData) } >
                                <div className="flex flex-wrap">
                                    <div className="md:w-1/2 w-full p-2">
                                        <div className="relative mb-2">
                                            <label htmlFor="type" className="leading-7 hidden md:text-xl text-white font-semibold md:block mb-2">Identifícate</label>
                                            <select defaultValue="null" id="type" className="w-full h-16 text-xl bg-gray-100 rounded-lg border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                {...register("type", {
                                                    required: "Campo requerido",
                                                    validate: (value) => {
                                                        if (value === "null") {
                                                            return "Campo requerido";
                                                        }
                                                    },
                                                })}
                                            >
                                                <option hidden disabled value="null">Identifícate</option>
                                                { types.map((item, index) => {
                                                    return (
                                                        <option key={ index } value={ item.id } >{ item.name }</option>
                                                    );
                                                }) }
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="type"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </div>
                                    </div>
                                    <div className="md:w-1/2 w-full p-2">
                                        <div className="relative mb-2">
                                            <label htmlFor="servicio" className="leading-7 hidden md:text-xl text-white font-semibold md:block mb-2">Categoría</label>
                                            <select defaultValue="null" id="service" className="w-full h-16 text-xl bg-gray-100 rounded-lg border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                {...register("service", {
                                                    required: "Campo requerido",
                                                    validate: (value) => {
                                                        if (value === "null") {
                                                            return "Campo requerido";
                                                        }
                                                    },
                                                })}
                                                onChange={ loadStates }
                                            >
                                                <option hidden disabled value="null">Categoría</option>
                                                { services.map((item, index) => {
                                                    return (
                                                        <option key={ index } value={ item.id } >{ item.name }</option>
                                                    );
                                                }) }
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="service"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </div>
                                    </div>
                                    <div className="md:w-1/6 w-0"></div>
                                    <div className="md:w-4/6 w-full p-2">
                                        <div className="relative mb-2">
                                            <label htmlFor="state" className="leading-7 hidden md:text-xl text-white font-semibold md:block mb-2">Departamento</label>
                                            <select defaultValue="null" id="state" className="w-full h-16 text-xl bg-gray-100 rounded-lg border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                {...register("state", {
                                                    required: "Campo requerido",
                                                    validate: (value) => {
                                                        if (value === "null") {
                                                            return "Campo requerido";
                                                        }
                                                    },
                                                })}
                                            >
                                                <option hidden disabled value="null">Departamento</option>
                                                { states.map((item, index) => {
                                                    return (
                                                        <option key={ index } value={ item.id } >{ item.name }</option>
                                                    );
                                                }) }
                                            </select>
                                            <ErrorMessage
                                                errors={ errors }
                                                name="state"
                                                render={({ message }) => <span className="error-message">{ message }</span>}
                                            />
                                        </div>
                                    </div>
                                    <div className="md:w-1/6 w-0"></div>
                                    <div className="w-full px-pb-2 pt-4 text-center">
                                        {/* <a href="/results" className="text-xl rounded-full font-semibold bg-theme transition-colors hover:bg-dark-theme px-10 py-4">Buscar</a> */}
                                        <button type="submit" className="text-xl rounded-full font-normal bg-theme transition-colors hover:bg-dark-theme px-10 py-4">Buscar</button>
                                    </div>
                                </div>
                            </form>
                        ) }
                        { (services.length === 0) && (
                            <Preload />
                        ) }
                    </section>
                </div>
                <div className="w-full h-fit">
                    <Navigate />
                </div>
            </div>
        </div>
    );
};

export default Searcher;
