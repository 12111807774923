import axios from 'axios';
import { useCallback, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useWindowDimensions from '../hooks/useWindowDimensions';

import Navbar from "../layout/Navbar";
import Navigate from "../layout/Navigate";

import Ubication from '../components/Details/Ubication';

const Details = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});

    let { slug } = useParams();
    const { height } = useWindowDimensions();

    const loadData = useCallback(() => {
        let uri = `${process.env.REACT_APP_API_URI}center/info/${slug}`;
        console.log(uri);
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }
        
        axios.get(uri, config)
        .then(response => {
            console.log(response.data);
            setData(response.data);
        })
        .catch(error => {
            console.log(error);
        });

        setIsLoading(true);
    }, []);

    useEffect(() => {
        if (isLoading === false) {
            loadData();
        }
    }, []);

    return (
        <div id="Results" className="h-full">
            <div className="flex flex-wrap">
                <div className="w-full h-fit">
                    <Navbar before={ '/results' } />
                </div>
                <div className="w-full">
                    <div>
                        <section className="md:mx-14 mx-7 text-white">
                            <div className="text-center pt-5">
                                { data.name && (
                                    <div className="bg-theme py-4 px-6 rounded-full md:inline-block">
                                        <h1 className="inline lg:text-xl txt-base font-bold">
                                            <FontAwesomeIcon icon="fa-solid fa-house-chimney-medical" className="mr-5" />{ `${(data.short_name).slice(0, 33)}` }{ data.short_name.length > 33 ? "..." : "" }
                                        </h1>
                                    </div>
                                ) }
                                { !data.name && (
                                    <div className="bg-theme py-4 px-6 rounded-full md:inline-block w-1/2 animate-pulse">
                                        <h1 className="inline lg:text-xl txt-base font-bold">
                                            <br />
                                        </h1>
                                    </div>
                                ) }
                            </div>
                        </section>
                        <section className="mt-8">
                            { data.latitude && (
                                <Ubication 
                                    latitude={ data.latitude }
                                    longitude={ data.longitude }
                                />
                            ) }
                            { !data.latitude && (
                                <div className="w-full bg-theme animate-pulse h-map-details"  />
                            ) }
                        </section>
                        <section className="bg-theme px-4 py-4">
                            <div className="flex flex-wrap">
                                <div className="w-full px-2 py-4 flex justify-center">
                                    { !data.manager && (
                                        <div className="text-left md:text-lg text-base bg-dark-theme bg-opacity-35 animate-pulse w-52 px-5 py-1 rounded-xl">
                                            <FontAwesomeIcon icon="fa-solid fa-house-chimney-medical" />
                                        </div>
                                    ) }
                                    { data.manager && (
                                        <div className="text-center md:text-xl text-base bg-transparent px-0 py-1 rounded-xl transition-colors hover:bg-opacity-75">
                                            <FontAwesomeIcon icon="fa-solid fa-house-chimney-medical" />
                                            &nbsp;&nbsp;
                                            <span className="text-white" >{ data.name }</span>
                                        </div>
                                    ) }
                                </div>
                                <div className="lg:w-1/2 w-full px-2 py-4 flex justify-center">
                                    { !data.manager && (
                                        <div className="text-left md:text-lg text-base bg-dark-theme bg-opacity-35 animate-pulse w-52 px-5 py-1 rounded-xl">
                                            <FontAwesomeIcon icon="fa-solid fa-user" />
                                        </div>
                                    ) }
                                    { data.manager && (
                                        <div className="text-center md:text-lg text-base bg-transparent px-0 py-1 rounded-xl transition-colors hover:bg-opacity-75">
                                            <FontAwesomeIcon icon="fa-solid fa-user" />
                                            &nbsp;&nbsp;
                                            <span className="text-white" >Encargado:&nbsp;{ data.manager }</span>
                                        </div>
                                    ) }
                                </div>
                                <div className="lg:w-1/2 w-full px-2 py-4 flex justify-center">
                                    { !data.address && (
                                        <div className="text-left md:text-lg text-base bg-dark-theme bg-opacity-35 animate-pulse w-52 px-5 py-1 rounded-xl">
                                            <FontAwesomeIcon icon="fa-solid fa-location-dot" />
                                        </div>
                                    ) }
                                    { data.address && (
                                        <div className="text-center md:text-lg text-base bg-transparent px-0 py-1 rounded-xl transition-colors hover:bg-opacity-75">
                                            <FontAwesomeIcon icon="fa-solid fa-location-dot" />
                                            &nbsp;&nbsp;
                                            <span className="text-white" >{ data.address }</span>
                                        </div>
                                    ) }
                                </div>
                                <div className="lg:w-1/2 w-full px-2 py-4 flex justify-center">
                                    { !data.phone && (
                                        <div className="text-left md:text-lg text-base bg-dark-theme bg-opacity-35 animate-pulse w-52 px-5 py-1 rounded-xl">
                                            <FontAwesomeIcon icon="fa-solid fa-phone" />
                                        </div>
                                    ) }
                                    { data.phone && (
                                        <a href={ `tel:503${ data.phone }` } className="md:text-center text-left md:text-lg text-base bg-dark-theme px-4 py-1 rounded-xl transition-colors hover:bg-opacity-75">
                                            <FontAwesomeIcon icon="fa-solid fa-phone" />
                                            &nbsp;&nbsp;
                                            <span className="text-white" >{ (data.phone).toString().substring(0, 4) }-{ (data.phone).toString().substring(4, 8) }</span>
                                        </a>
                                    ) }
                                </div>
                                <div className="lg:w-1/2 w-full px-2 py-4 flex justify-center">
                                    { data.email === undefined && (
                                        <div className="text-left md:text-lg text-base bg-dark-theme bg-opacity-35 animate-pulse w-52 px-5 py-1 rounded-xl">
                                            <FontAwesomeIcon icon="fa-solid fa-envelope" />
                                        </div>
                                    ) }
                                    { (data.email !== undefined) && (
                                        <>
                                            { data.email !== null && (
                                                <a href={ `mailto:${ data.email }` } className="md:text-center text-left md:text-lg text-base bg-dark-theme px-3 py-1 rounded-xl transition-colors hover:bg-opacity-75">
                                                    <FontAwesomeIcon icon="fa-solid fa-envelope" />
                                                    &nbsp;&nbsp;
                                                    <span className="text-white" >{ data.email }</span>
                                                </a>
                                            ) }
                                            { data.email === null && (
                                                <div className="md:text-center text-left md:text-lg text-base bg-dark-theme px-3 py-1 rounded-xl transition-colors hover:bg-opacity-75">
                                                    <FontAwesomeIcon icon="fa-solid fa-envelope" />
                                                    &nbsp;&nbsp;
                                                    No disponible
                                                </div>
                                            ) }
                                        </>
                                    ) }
                                </div>
                                <div className="lg:w-1/2 w-full px-2 py-4 flex justify-center">
                                    {  data.schedules !== undefined && (
                                        <>
                                            { data.schedules.length > 1 && (
                                                <div className="w-full">
                                                    <div className="schedule-attetion bg-dark-theme relative mb-1 rounded-xl">
                                                        <input type="checkbox" id="schedule" className="toggle hidden" />
                                                        <label className="title-accordion" htmlFor="schedule" >
                                                            <FontAwesomeIcon icon="fa-regular fa-clock" />&nbsp;&nbsp;Horario de atención
                                                        </label>
                                                        <div className="content">
                                                            <div className="p-4 rounded-2xl">
                                                                <div className="md:text-lg text-base">
                                                                    { data.schedules.map((item, index) => {
                                                                        return (
                                                                            <div className="grid grid-cols-2 w-full">
                                                                                <div className="md:text-lg text-sm">{ item.day }</div>
                                                                                <div className="md:text-lg text-sm">{ item.entry }&nbsp;-&nbsp;{ item.departure }</div>
                                                                            </div>
                                                                        );
                                                                    }) }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) }
                                            { data.schedules.length === 0 && (
                                                <div className="text-left md:text-lg text-base bg-dark-theme w-full px-3 py-3 rounded-xl">
                                                    <h3 className="font-bold">
                                                        <FontAwesomeIcon icon="fa-regular fa-clock" />&nbsp;&nbsp;Horario de atención
                                                    </h3>
                                                    <br />
                                                    <p className="text-center">No disponible</p>
                                                </div>
                                            ) }
                                        </>
                                    ) }
                                    {  data.schedules === undefined && (
                                        <div className="text-left md:text-lg text-base bg-dark-theme bg-opacity-35 animate-pulse w-full px-5 py-1 rounded-xl">
                                            <br />
                                        </div>
                                    ) }
                                </div>
                                <div className="lg:w-1/2 w-full px-2 py-4 flex justify-center">
                                    { data.categories !== undefined && (
                                        <>
                                            { data.categories.length > 0 && (
                                                <div className="w-full">
                                                    <div className="bg-dark-theme relative mb-1 rounded-xl">
                                                        <input type="checkbox" id="services" className="toggle hidden" />
                                                        <label className="title-accordion" htmlFor="services" >
                                                            <FontAwesomeIcon icon="fa-solid fa-briefcase-medical" />&nbsp;&nbsp;Servicios
                                                        </label>
                                                        <div className="content">
                                                            <div className="p-4 rounded-2xl">
                                                                { data.categories.map((item, index) => {
                                                                    return (
                                                                        <div key={ index } className="more-info-details">
                                                                            <h3 >{ item.name }</h3>
                                                                            <ul>
                                                                                { item.services.map((service, index) => {
                                                                                    return (
                                                                                        <li key={ index }>{ service.name }</li>
                                                                                    );
                                                                                }) }
                                                                            </ul>
                                                                        </div>
                                                                    );
                                                                }) }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) }
                                            { data.categories.length === 0 && (
                                                <div className="text-left md:text-lg text-base bg-dark-theme w-full px-3 py-3 rounded-xl">
                                                    <h3 className="font-bold">
                                                        <FontAwesomeIcon icon="fa-solid fa-briefcase-medical" />&nbsp;&nbsp;Servicios
                                                    </h3>
                                                    <br />
                                                    <p className="text-center">No disponibles</p>
                                                </div>
                                            ) }
                                        </>
                                    ) }
                                    { data.categories === undefined && (
                                        <div className="text-left md:text-lg text-base bg-dark-theme bg-opacity-35 animate-pulse w-full px-5 py-1 rounded-xl">
                                            <br />
                                        </div>
                                    ) }
                                </div>
                                <div className="lg:w-1/6 w-0"></div>
                                <div className="lg:w-4/6 w-full px-2 py-4 flex justify-center">
                                    { data.more_info !== undefined && (
                                        <>
                                            { data.more_info !== null && (
                                                <div className="text-left md:text-lg text-base bg-dark-theme w-full px-3 py-3 rounded-xl">
                                                    <h3 className="font-bold">
                                                        <FontAwesomeIcon icon="fa-solid fa-file-lines" />&nbsp;&nbsp;Información adicional
                                                    </h3>
                                                    <br />
                                                    <p className="text-justify" dangerouslySetInnerHTML={{ __html: data.more_info }} />
                                                </div>
                                            ) }
                                        </>
                                    ) } 
                                    { data.categories === undefined && (
                                        <div className="text-left md:text-lg text-base bg-dark-theme bg-opacity-35 animate-pulse w-full px-5 py-1 rounded-xl">
                                            <br />
                                        </div>
                                    ) }
                                </div>
                                <div className="lg:w-1/6 w-0"></div>
                                <div className="lg:w-1/6 w-0"></div>
                                <div className="lg:w-4/6 w-full px-2 py-4 flex justify-center flex-col items-center">
                                    { (data.facebook !== undefined || data.instagram !== undefined || data.twitter !== undefined) && (
                                        <>
                                            { (data.facebook !== null || data.instagram !== null || data.twitter !== null) && (     
                                                <div className="text-center md:text-lg text-base">
                                                    <h3 className="font-bold">Redes sociales</h3>
                                                </div>
                                            ) }
                                        </>
                                    ) }
                                    <div className="flex flex-wrap md:w-auto w-full">
                                        { data.facebook !== undefined && (
                                            <>
                                                { data.facebook !== null && (
                                                    <div className="w-1/3 p-4 flex justify-center">
                                                        <a href={ data.facebook } target="_blank" rel="noreferrer" className="text-4xl p-3 bg-facebook transition-colors rounded-xl hover:bg-dark-facebook">
                                                            <FontAwesomeIcon icon="fa-brands fa-facebook" />
                                                        </a>
                                                    </div>
                                                ) }
                                            </>
                                        ) }
                                        { data.instagram !== undefined && (
                                            <>
                                                { data.instagram !== null && (
                                                    <div className="w-1/3 p-4 flex justify-center">
                                                        <a href={ data.instagram } target="_blank" rel="noreferrer" className="text-4xl p-3 bg-instagram transition-colors rounded-xl hover:bg-dark-instagram">
                                                            <FontAwesomeIcon icon="fa-brands fa-instagram" />
                                                        </a>
                                                    </div>
                                                ) }
                                            </>
                                        ) }
                                        { data.twitter !== undefined && (
                                            <>
                                                { data.twitter !== null && (
                                                    <div className="w-1/3 p-4 flex justify-center">
                                                        <a href={ data.twitter } target="_blank" rel="noreferrer" className="text-4xl p-3 bg-twitter transition-colors rounded-xl hover:bg-dark-twitter">
                                                            <FontAwesomeIcon icon="fa-brands fa-twitter" />
                                                        </a>
                                                    </div>
                                                ) }
                                            </>
                                        ) }
                                    </div>
                                </div>
                                <div className="lg:w-1/6 w-0"></div>
                                <div className="w-full px-2 pt-6 pb-4">
                                    <div className="text-center">
                                        { data.latitude && (
                                            <a href={ `https://www.google.com/maps/place/${data.latitude},${data.longitude}` } target="_blank" rel="noreferrer" className="bg-red-700 text-lg text-white transition-colors mx-2 px-4 py-3 font-bold rounded-lg hover:bg-red-800">
                                                <FontAwesomeIcon icon="fa-solid fa-location-arrow" />&nbsp;&nbsp;Como llegar
                                            </a>
                                        ) }
                                        { !data.latitude && (
                                            <button className="bg-red-700 text-lg text-white transition-colors bg-opacity-70 mx-2 px-4 py-2 font-bold rounded-lg w-36 animation-pulse">
                                                <br/>
                                            </button>
                                        ) }
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div className="w-full h-fit">
                    <Navigate />
                </div>
            </div>
        </div>
    );
};

export default Details;
