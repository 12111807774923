import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Brand from '../assets/img/brand.jpg';

const Navbar = ({ before }) => {
    const clearStorage = () => {
        if (before === '/search') {
            sessionStorage.removeItem('search');
        }
    }

    return (
        <>
            <div className="h-24"></div>
            <div id="Navbar" className="fixed top-0 w-full max-w-full min-w-full z-10">
                <div className="bg-white text-black px-5 py-4">
                    <div className="flex flex-wrap justify-between items-center">
                        <div>
                            <Link to={ before } className="block bg-white transition-colors px-4 py-2 rounded-full text-center text-theme hover:bg-slate-300" onClick={ () => clearStorage() } >
                                <FontAwesomeIcon icon="fa-solid fa-angle-left" size="2x" />
                            </Link>
                        </div>
                        <div>
                            <img
                                src={ Brand }
                                className="w-16"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Navbar;
