
const Footer = () => {
    return (
        <div id="Footer" className="w-full">
            <div className="w-full text-center md:text-base text-sm py-8">
                <span className="font-normal">Hecho en El Salvador por <a className="text-link font-bold transition-colors hover:text-dark-link" href="https://conexion.sv" target='_blank' >CONEXION</a></span>
            </div>
        </div>
    );
};

export default Footer;
