import axios from 'axios';
import { useCallback, useEffect, useState } from "react";

import useWindowsDimension from '../hooks/useWindowDimensions';

import Navbar from "../layout/Navbar";
import Navigate from "../layout/Navigate";
import Footer from "../layout/Footer";

import Banner from '../assets/img/banner.png';

const preload = [1, 2, 3, 4, 5];

const Ranking = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState(undefined);

    const { height } = useWindowsDimension();

    const loadData = useCallback(() => {
        let uri = `${process.env.REACT_APP_API_URI}record/statistic`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }
        
        axios.get(uri, config)
        .then(response => {
            setItems(response.data);
        })
        .catch(error => {
            console.log(error);
        });

        setIsLoading(true);
    } ,[]);

    useEffect(() => {
        if (isLoading === false) {
            loadData();
        }
    }, []);

    return (
        <div id="Ranking" className="h-full">
            <div className="h-full">
                <div className="w-full h-fit">
                    <Navbar before={ "/search" } />
                </div>
                <div className="w-full">
                    <section className="text-white h-full w-full mt-5">
                        <div className="md:mx-14 mx-7 text-center mb-5">
                            <h1 className="text-2xl font-bold">Búsquedas más populares</h1>
                        </div>
                        <div className="bg-theme" style={{ height: `${ items !== undefined && items.length === 0 ? (height - 480) + "px" : "auto" }` }}>
                            <div className="flex flex-wrap py-3 h-full items-center">
                                { items !== undefined && (
                                    <>
                                        { items.length === 0 && (
                                            <div className="w-full p-4">
                                                <div className="flex rounded-lg p-8 items-center">
                                                    <div className="flex-grow">
                                                        <h2 className="text-xl font-medium rounded-lg text-center">
                                                            Aún no se han realizado búsquedas.
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        ) }
                                        { items.length > 0 && (
                                            items.map((item, key) => {
                                                return (
                                                    <div key={ key } className="lg:w-1/2 w-full p-4">
                                                        <div className="flex bg-body rounded-lg p-8 items-center">
                                                            <div className="w-16 h-16 mr-4 inline-flex items-center justify-center rounded-full bg-theme flex-shrink-0">
                                                                <img
                                                                    src={ process.env.REACT_APP_URI_PHOTO + item.icon }
                                                                    className="w-8 h-8"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="flex-grow">
                                                                <h2 className="text-white text-xl title-font font-bold mb-2">Servicio { item.category }</h2>
                                                                <p className="text-lg font-light">{ item.percent }%</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) }
                                    </>
                                ) }
                                { items === undefined && (
                                    preload.map((item) => {
                                        return (
                                            <div key={ item } className="lg:w-1/2 w-full p-4">
                                                <div className="flex bg-body rounded-lg p-8 items-center">
                                                    <div className="w-16 h-16 rounded-full bg-theme flex-shrink-0 animate-pulse mr-4">
                                                        <br />
                                                    </div>
                                                    <div className="flex-grow">
                                                        <h2 className="bg-theme text-xl font-medium mb-2 rounded-lg animate-pulse">
                                                            <br/>
                                                        </h2>
                                                        <p className="bg-theme text-lg rounded-lg animate-pulse">
                                                            <br />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) }
                            </div>
                        </div>
                    </section>
                </div>
                <div className="w-full">
                    <div className="bg-white flex justify-center w-full xl:py-6 md:py-3 md:px-0 py-5 px-2">
                        <img
                            src={ Banner }
                            className="2xl:w-auto md:w-1/3 w-auto h-auto"
                            alt=""
                        />
                    </div>
                    <Footer />
                </div>
                <div className="w-full h-fit">
                    <Navigate />
                </div>
            </div>
        </div>
    );
};

export default Ranking;
