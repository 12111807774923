import Swal from 'sweetalert2';

export const generateAlert = (data) => {
    Swal.fire({
        title: data.message,
        icon: data.type,
        allowOutsideClick: false,
        confirmButtonText: 'OK'
    });
};

