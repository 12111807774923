import { useEffect, useContext } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';

const ScrollToTop = () => {
    const history = useContext(UNSAFE_NavigationContext).navigator;

    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });

        return () => {
            unlisten();
        }
        
    }, [history]);

    return (null);
}

export default ScrollToTop;
