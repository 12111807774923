const MapPopUp = (props) => {
    return (
        <div className="w-full">
            <h3 className="font-bold text-sm text-theme">{ props.name }</h3>
            { props.photo !== null && (
                <>
                    <br/>
                    <img src={ process.env.REACT_APP_URI_PHOTO + props.photo } alt="" />
                </>
            ) }
            <br />
            <a href={ `details/${props.slug}` } className="bg-theme text-white w-full py-2 px-2 rounded-xl transition-colors block text-sm hover:bg-dark-theme">Ver más</a>
        </div>
    );
};

export default MapPopUp;
