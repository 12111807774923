import axios from 'axios';
import { useCallback, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Navbar from "../layout/Navbar";
import Navigate from "../layout/Navigate";

import Cards from '../components/Results/Cards';
import Map from '../components/Results/Map';

const Results = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [viewMap, setViewMap] = useState(false);
    const [data, setData] = useState([]);
    const [items, setItems] = useState([]);
    // const [empty, setEmpty] = useState(0);

    const loadResults = useCallback(() => {
        let uri = `${process.env.REACT_APP_API_URI}center/search`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }

        let query = sessionStorage.getItem('search');
        // console.log(JSON.parse(data));
        axios.post(uri, query, config)
        .then(response => {
            let result = response.data;
            
            setData(result);
            setItems(result.data);
        })
        .catch(error => {
            console.log(error);
        });

        setIsLoading(true);
    } ,[]);

    const loadMap = (value) => {
        setViewMap(value);
    };

    useEffect(() => {
        if (sessionStorage.getItem('search') === null) {
            navigate('/search');
        }

        if (isLoading === false) {
            loadResults();
        }
    }, []);

    return (
        <div id="Results" className="h-full">
            <div className="flex flex-wrap">
                <div className="w-full h-fit">
                    <Navbar before={ "/search" } />
                </div>
                <div className="w-full">
                    <section className="text-white h-full w-full">
                        <div className="md:mx-14 mx-7 text-center mb-5">
                            <h1 className="text-2xl font-bold">Resultados de la búsqueda</h1>
                        </div>
                        <div className="md:mx-14 mx-7 text-center mb-5">
                            { data.location !== undefined && (
                                <div className="bg-theme py-3 px-4 rounded-full md:inline-block">
                                    { data.location.state }
                                </div>
                            ) }
                            { data.location === undefined && (
                                <div className="bg-theme py-3 px-4 md:w-1/4 w-full bg-opacity-35 animate-pulse rounded-full md:inline-block">
                                    <br />
                                </div>
                            ) }
                        </div>
                        <div className="flex md:justify-center md:flex-nowrap flex-wrap mb-4 md:mx-14 mx-7">
                            <div className="w-1/2 md:w-auto p-2">
                                <button type="button" className="w-full bg-white text-lg text-theme transition-colors mx-2 px-4 py-2 font-bold rounded-lg hover:bg-slate-300" onClick={ () => loadMap(false) } >Ver listado</button>
                            </div>
                            <div className="w-1/2 md:w-auto p-2">
                                <button type="button" className="w-full bg-white text-lg text-theme transition-colors mx-2 px-4 py-2 font-bold rounded-lg hover:bg-slate-300" onClick={ () => loadMap(true) }>Ver mapa</button>
                            </div>
                        </div>
                        { (viewMap === false) && (    
                            <div className="flex flex-wrap md:mx-14 mx-5 mb-5">
                                { items.map((item, index) => {
                                    return (
                                        <div key={ index } className="lg:w-1/4 w-1/2 p-2">
                                            <Link to={ `/details/${item.slug}` } className="bg-theme w-full block text-center p-4 h-full rounded-xl transition-colors hover:bg-dark-theme">
                                                <div className="flex justify-center flex-col h-full min-h-full">
                                                    <FontAwesomeIcon icon="fa-solid fa-house-chimney-medical" size="4x" />
                                                    <div className="mt-4 w-full h-full flex items-center justify-center">
                                                        <p className="lg:text-xl text-sm title-card-result">{ `${(item.short_name).slice(0, 33)}` }{ item.short_name.length > 33 ? "..." : "" }</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                }) }
                            </div>
                        ) }
                        { (viewMap === true) && (
                            <div className="flex flex-wrap map-box-container">
                                <Map data={ data } />
                            </div>
                        ) }
                    </section>
                </div>
                <div className="w-full h-fit">
                    <Navigate />
                </div>
            </div>
        </div>
    );
};

export default Results;
